<script>
	/* -------- */
	import Debugger from './components/functional/Debugger.svelte';
	import {Router} from 'svelte-router-spa'
	import {routes} from './routes/routes'
	import {hideDebugPanel} from '../src/stores/ui.js'

	/* -------- */

	let debug;

	$: { $hideDebugPanel === true ? debug = true : debug = false}

</script>

<Router {routes}/>

{#if debug === true}
	<Debugger/>
{/if}